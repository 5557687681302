/**
 * Router for Admin Perspective > EGM
 */
const routes = [{
    path: 'egm/:egm_id',
    name: 'Admin/EGM',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/egms/EgmsPage.vue'),
    children: [
      //Redirect to first child
      {
        path:'',
        redirect: '/panel/admin/egm/:egm_id/mix'
      },
      //Mix Configuration tab
      {
        path:'mix',
        name:'Admin/Egm/Mix',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/egms/mix/MixTab.vue'),
      },
      //Zones tab
      {
        path:'zone',
        name:'Admin/Egm/Zone',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/egms/zones/ZonesTab.vue'),
      },
      //Devices tab
      {
        path:'device',
        name:'Admin/Egm/Device',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/egms/devices/DevicesTab.vue'),
      },
      //Devices tab
      {
        path:'history',
        name:'Admin/Egm/History',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/egms/history/HistoryTab.vue'),
      },
    ]
}];
export default routes;