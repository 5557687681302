/**
 * Main Router
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery'

import AdminPerspectiveRoutes from '@/routes/AdminPerspectiveRoutes';  
import SystemPerspectiveRoutes from '@/routes/SystemPerspectiveRoutes'; 
import TechnicalPerspectiveRoutes from '@/routes/TechnicalPerspectiveRoutes'; 
import OperationalPerspectiveRoutes from '@/routes/OperationalPerspectiveRoutes';
import FinancialPerspectiveRoutes from '@/routes/FinancialPerspectiveRoutes';

Vue.use(VueRouter) 

const routes = [

  // Index redirect
  {
    path: '',
    redirect: '/panel',
    meta: { requiresAuth: true },
   },

  // Login View
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'), 
    meta: { requiresGuest: true },
  },

  // Admin panel view
  {
    path: '/panel/',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/App.vue'),
    meta: { requiresAuth: true },
    children: [
      // Redirect to first child
      {
        path: '',
        redirect: '/panel/admin',
      },

      AdminPerspectiveRoutes,

    ]
  },
  
  //System panel view
  {
    path: '/panel/',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/App.vue'),
    meta: { requiresAuth: true },
    children: [
      // Redirect to first child
      {
        path: '',
        redirect: '/panel/system',
      },

      SystemPerspectiveRoutes,

    ]
  },

  //Technical panel view
  {
    path: '/panel/',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/App.vue'),
    meta: { requiresAuth: true },
    children: [
      // Redirect to first child
      {
        path: '',
        redirect: '/panel/technical',
      },

      TechnicalPerspectiveRoutes,

    ]
  },

  //Operational panel view
  {
    path: '/panel/',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/App.vue'),
    meta: { requiresAuth: true },
    children: [
      // Redirect to first child
      {
        path: '',
        redirect: '/panel/operational',
      },

      OperationalPerspectiveRoutes,

    ]
  },

  //Financial panel view
  {
    path: '/panel/',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/App.vue'),
    meta: { requiresAuth: true },
    children: [
      // Redirect to first child
      {
        path: '',
        redirect: '/panel/financial',
      },

      FinancialPerspectiveRoutes,

    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

    // Close modals on route change 
    $('.modal').modal('hide');   

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (router.app.$store.getters.loggedIn === false) {

            next({
                path: '/login',
            })
        } else { 
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (router.app.$store.getters.loggedIn === true) {
            next({
                path: '/',
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})


export default router
