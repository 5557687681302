/**
 * Includes 
 */
import Vue from 'vue';
import Toasted from 'vue-toasted';
import AOS from 'aos';
import 'aos/dist/aos.css';

import store from './store';
import router from './routes/MainRoutes.js'; 

// Vuetify
import vuetify from './config/VuetifyConfig.js';

// Main app
import App from './App.vue'
window.Vue = Vue;

// SCSS 
import './assets/scss/style.scss';
import './assets/scss/temp.scss';

/**
 * Config
 */

/**
 *  Moment
 */
Vue.use(require('vue-moment'));

/**
 * Socket.IO
 */
import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
    debug: false,
    connection: 'https://signal-ct.cloud.ro/',
    vuex: {
        store,
        actionPrefix: 'SOCKET',
        mutationPrefix: 'SOCKET'
    },
    options: { 
      query: {
        client_type: 'adminClient',
      }
    } 
}))



// Global libs
require('./config/GlobalConfig.js'); 

//Images Global Endpoint
Vue.prototype.getImage = function(fileName) {
  return 'https://r2a-new.cloud.ro/business/api/v1/media/' + fileName;
}

//Check if typeof Object and return html
Vue.prototype.checkType = function(value) {
  if(typeof value !== 'object'){
    return value;
  } else {
    let resVal = (value.value % 1 ===0) ? value.value : value.value.toFixed(2);
    let resChange = (value.change % 1 ===0) ? value.change : value.change.toFixed(2);
    if(value.change < 0)
    return resVal +'<div class="value-change min">'+resChange+'%</div>';
    else if(value.change > 0)
    return resVal +'<div class="value-change plus">'+resChange+'%</div>';
    else
    return resVal +'<div class="value-change none">'+resChange+'%</div>';
  }
}

//Check if typeof Object and return formated time
Vue.prototype.checkTypeDuration = function(value) {

  let duration = function(value){
    let sec_num = parseInt(value, 10);
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor(sec_num / 60) % 60;
    let seconds = sec_num % 60;
    
    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : Vue.prototype.checkTypeCurrency(v))
        .filter((v,i) => v !== "00" || i > 0)
        .join(":");
  }
    

  if(typeof value !== 'object'){
    return duration(value);
  } else {
    let resChange = (value.change % 1 === 0) ? value.change : value.change.toFixed(2);
    if(value.change < 0)
    return duration(value.value) +'<div class="value-change min">'+resChange+'%<span class="val-tooltip">'+ duration(value.cValue) +'</span></div>';
    else if(value.change > 0)
    return duration(value.value) +'<div class="value-change plus">'+resChange+'%<span class="val-tooltip">'+ duration(value.cValue) +'</span></div>';
    else
    return duration(value.value) +'<div class="value-change none">'+resChange+'%<span class="val-tooltip">'+ duration(value.cValue) +'</span></div>';
  }
}

//Check if typeof Object and return formated number
Vue.prototype.checkTypeCurrency = function(value, isPercent) {

  let currency = function(value){
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  if(typeof value !== 'object'){
    return (value % 1 === 0) ? currency(value) : currency(value.toFixed(2));
  } else {
    let resVal = (value.value % 1 ===0) ? value.value : value.value.toFixed(2);
    let resCval = (value.cValue % 1 ===0) ? value.cValue : value.cValue.toFixed(2);
    let resChange = (value.change % 1 ===0) ? value.change : value.change.toFixed(2);

    if(value.change < 0)
    return currency(resVal) + ((isPercent)?'%':'') + '<div class="value-change min">'+resChange+'%<span class="val-tooltip">'+ currency(resCval)+ ((isPercent)?'%':'')+'</span></div>';
    else if(value.change > 0)
    return currency(resVal) + ((isPercent)?'%':'') + '<div class="value-change plus">'+resChange+'%<span class="val-tooltip">'+ currency(resCval)+((isPercent)?'%':'')+'</span></div>';
    else
    return currency(resVal) + ((isPercent)?'%':'') + '<div class="value-change none">'+resChange+'%<span class="val-tooltip">'+ currency(resCval)+((isPercent)?'%':'')+'</span></div>';
  }
}



Vue.prototype.formatDuration = function(value) {
  let sec_num = parseInt(value, 10);
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;
  
  return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":");
}

/*
* Duration filter
* return seconds value as h:mm:ss time stamp 
*/

Vue.filter('duration', function(value){
  let sec_num = parseInt(value, 10);
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;
  
  return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : Vue.prototype.checkTypeCurrency(v))
      .filter((v,i) => v !== "00" || i > 0)
      .join(":");
});

/*
* Format currency number 
*/
Vue.filter('currency', function(value){
var parts = value.toString().split(".");
parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
return parts.join(".");
});


// Axios config
axios.defaults.baseURL = 'https://r2a-new.cloud.ro/business/api/v1/'; 

const token = localStorage.getItem('token');
if(token) {
  const auth = JSON.parse(token);
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.access_token;
} 

// Toasted config
Vue.use(Toasted, {   
   theme: "toasted-primary", 
   position: "top-center", 
   duration : 3000
});


/**
 * Build
 */
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created() {
  	AOS.init({
  		duration: 300,
  		easing: 'ease',
  		once: true,
      offset: 0,
      anchorPlacement: 'top',
  	});
  },
  render: h => h(App)
}).$mount('#app')
