/**
 * Router for Operational Perspective > Casino
 */
 const routes = [
    {
        path: 'casino',
        name: 'Operational/Casino',
        component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/CasinoList.vue'),
    },
    {
        path: 'casino/:casino_id',
        name: 'Operational/Casino',
        component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/CasinoPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/operational/casino/:casino_id/info',
            },
            // Info Tab
            {
                path: 'info',
                name: 'Operational/Casino/Info',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/info/InfoTab.vue'),
            },
            // Zones Tab
            {
                path: 'zone',
                name: 'Operational/Casino/Zone',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/zones/ZonesTab.vue'),
            },
            // Players Tab
            {
                path: 'player',
                name: 'Operational/Casino/Player',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/players/PlayersTab.vue'),
            },
            // Sessions Tab
            {
                path: 'session',
                name: 'Operational/Casino/Session',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/sessions/SessionsTab.vue'),
            },
            // Tops Tab
            {
                path: 'top',
                name: 'Operational/Casino/Top',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/tops/TopsTab.vue'),
            },
            // Alarms Tab
            {
                path: 'alarm',
                name: 'Operational/Casino/Alarm',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/casino/alarms/AlarmsTab.vue'),
            }
        ]
    },
    {
        path: 'casino/:casino_id/zone/:zone_id',
        name: 'Operational/Zone',
        component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/ZonePage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/operational/casino/:casino_id/zone/:zone_id/info',
            },
            // Info Tab
            {
                path: 'info',
                name: 'Operational/Casino/Zone/Info',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/info/InfoTab.vue'),
            },
            // EGMS Tab
            {
                path: 'egm',
                name: 'Operational/Casino/Zone/EGM',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/egms/EgmsTab.vue'),
            },
            // Players Tab
            {
                path: 'player',
                name: 'Operational/Casino/Zone/Player',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/players/PlayersTab.vue'),
            },
            // Sessions Tab
            {
                path: 'session',
                name: 'Operational/Casino/Zone/Session',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/sessions/SessionsTab.vue'),
            },
            // Tops Tab
            {
                path: 'top',
                name: 'Operational/Casino/Zone/Top',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/tops/TopsTab.vue'),
            },
            // Alarms Tab
            {
                path: 'alarm',
                name: 'Operational/Casino/Zone/Alarm',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/zone/alarms/AlarmsTab.vue'),
            }
        ]
    },
    {
        path: 'casino/:casino_id/egm/:egm_id',
        name: 'Operational/Egm',
        component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/egm/EgmPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/operational/casino/:casino_id/egm/:egm_id/brief',
            },
            // Brief Tab
            {
                path: 'brief',
                name: 'Operational/Casino/Egm/Brief',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/egm/brief/BriefTab.vue'),
            },
            // List of Players Tab
            {
                path: 'player',
                name: 'Operational/Casino/Egm/Player',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/egm/players/PlayersTab.vue'),
            },
            // List of Games Tab
            {
                path: 'game',
                name: 'Operational/Casino/Egm/Game',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/egm/games/GamesTab.vue'),
            },
            // List of Sessions Tab
            {
                path: 'session',
                name: 'Operational/Casino/Egm/Session',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/egm/sessions/SessionsTab.vue'),
            }
        ]
    },
    {
        path: 'casino/:casino_id/player/:player_id',
        name: 'Operational/Player',
        component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/player/PlayerPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/operational/casino/:casino_id/player/:player_id/brief',
            },
            // Brief Tab
            {
                path: 'brief',
                name: 'Operational/Casino/Player/Brief',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/player/brief/BriefTab.vue'),
            },
            // List of Players Tab
            {
                path: 'login',
                name: 'Operational/Casino/Player/Login',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/player/logins/LoginsTab.vue'),
            },
            // List of Games Tab
            {
                path: 'session',
                name: 'Operational/Casino/Player/Session',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/player/sessions/SessionsTab.vue'),
            },
            // List of Sessions Tab
            {
                path: 'moneyTransaction',
                name: 'Operational/Casino/Player/MoneyTransaction',
                component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/player/moneyTransactions/MoneyTransactionsTab.vue'),
            }
        ]
    },

];

export default routes;