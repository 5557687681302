/**
 * Router for System Perspective > Casino
 */
 const routes = [
    {
        path: 'hardware',
        name: 'System/Hardware',
        component: () => import( /* webpackChunkName: "system" */ '@/views/system/hardware/HardwarePage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/system/hardware/bootloader',
            },
            // Images Tab
            {
                path: 'bootloader',
                name: 'System/Hardware/Bootloader',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/hardware/bootloader/BootloaderTab.vue'),
            },
            // Links Tab
            {
                path: 'billAcceptor',
                name: 'System/Hardware/BillAcceptor',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/hardware/billAcceptors/BillAcceptorsTab.vue'),
            },
            // Business Settings Tab
            {
                path: 'touchController',
                name: 'System/Hardware/TouchController',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/hardware/touchControllers/TouchControllersTab.vue')
            }
        ]
    }

];

export default routes;