/**
 * Router for Technical Perspective > Device
 */
const routes = [
    {
        path: 'device/:device_id',
        name: 'Technical/Device',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/device/DevicePage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/technical/device/:device_id/dataLog',
            },
            // Data Log Tab
            {
                path: 'dataLog',
                name: 'Technical/Device/DataLog',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/device/dataLog/DataLogTab.vue'),
            },
            // Events Tab
            {
                path: 'event',
                name: 'Technical/Device/Events',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/device/events/EventsTab.vue'),
            },
            // Maintenace Log Tab
            {
                path: 'maintenanceLog',
                name: 'Technical/Device/Maintenance',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/device/maintenanceLog/MaintenanceTab.vue'),
            }
        ]
    },

];

export default routes;