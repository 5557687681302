/**
 * Router for Operational Perspective
 */
 import CasinoRoutes from '@/routes/operational/CasinoRoutes'; 

 
 const route = {
     path: 'operational',
     name: 'Operational',
     component: () => import( /* webpackChunkName: "operational" */ '@/views/operational/OperationalPerspective.vue'),
 
     children: [
         // Redirect to first child
         {
             path: '', 
             redirect: '/panel/operational/casino',
         },
         ...CasinoRoutes
     ]
 };
 
 export default route;