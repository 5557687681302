/**
 * Router for System Perspective
 */
import CasinoRoutes from '@/routes/system/CasinoRoutes'; 
import ManufacturerRoutes from '@/routes/system/ManufacturerRoutes';
import HardwareRoutes from '@/routes/system/HardwareRoutes';

const route = {
    path: 'system',
    name: 'System',
    component: () => import( /* webpackChunkName: "system" */ '@/views/system/SystemPerspective.vue'),

    children: [
        // Redirect to first child
        {
            path: '', 
            redirect: '/panel/system/casino',
        },
        ...CasinoRoutes,
        ...ManufacturerRoutes,
        ...HardwareRoutes
    ]
};

export default route;