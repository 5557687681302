/**
 * Router for System Perspective > Casino
 */
const routes = [
    {
        path: 'casino',
        name: 'System/Casino',
        component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/CasinoList.vue'),
    },
    {
        path: 'casino/:casino_id',
        name: 'System/Casino',
        component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/CasinoPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/system/casino/:casino_id/image',
            },
            // Images Tab
            {
                path: 'image',
                name: 'system/Casino/Zone',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/images/ImagesTab.vue'),
            },
            // Links Tab
            {
                path: 'link',
                name: 'System/Casino/Link',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/links/LinksTab.vue'),
            },
            // Business Settings Tab
            {
                path: 'businessSetting',
                name: 'System/Casino/BizSetting',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/businessSettings/BusinessTab.vue'),
            },
             // Business Settings Tab
            {
                path: 'sessionManage',
                name: 'System/Casino/SessionManagement',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/sessionManage/SessionManageTab.vue'),
            },
            // Contacts Tab
            {
                path: 'contact',
                name: 'System/Casino/Contact',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/contacts/ContactsTab.vue'),
            },
            {
                path: 'history',
                name: 'System/Casino/History',
                component: () => import( /* webpackChunkName: "system" */ '@/views/system/casino/history/HistoryTab.vue'),
            },
        ]
    },

];

export default routes;