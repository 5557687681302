/**
 * Router for financial Perspective > Casino
 */
 const routes = [
    {
        path: 'casino',
        name: 'Financial/Casino',
        component: () => import( /* webpackChunkName: "financial" */ '@/views/financial/casino/CasinoList.vue'),
    },
    {
        path: 'casino/:casino_id',
        name: 'Financial/Casino',
        component: () => import( /* webpackChunkName: "financial" */ '@/views/financial/casino/CasinoPage.vue'),
    }
];

export default routes;