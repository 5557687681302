/**
 * Router for Technical Perspective > Egm
 */
const routes = [
    {
        path: 'egm/:egm_id',
        name: 'Technical/Egm',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/egm/EgmPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/technical/egm/:egm_id/device',
            },
            // Devices Tab
            {
                path: 'device',
                name: 'Technical/Egm/Device',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/egm/devices/DevicesTab.vue'),
            },
            // Events Tab
            {
                path: 'event',
                name: 'Technical/Egm/Event',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/egm/events/EventsTab.vue'),
            },
            // Log Tab
            {
                path: 'log',
                name: 'Technical/Egm/Log',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/egm/log/LogTab.vue'),
            },
            // Maintenance Tab
            {
                path: 'maintenance',
                name: 'Technical/Egm/Maintenance',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/egm/maintenance/MaintenanceTab.vue'),
            },
            // Previews Tab
            {
                path: 'preview',
                name: 'Technical/Egm/Preview',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/egm/previews/PreviewsTab.vue'),
            },
        ]
    },

];

export default routes;