/**
 * Router for Technical Perspective > Zone
 */
 const routes = [
    {
        path: 'debug',
        name: 'Technical/Debug',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/debug/DebugPage.vue'),
       
    },

];

export default routes;