/**
 * Router for Admin Perspective
 */
import CasinoRoutes from '@/routes/admin/CasinoRoutes'; 
import EGMRoutes from '@/routes/admin/EgmRoutes';

const route = {
    path: 'admin',
    name: 'Admin',
    component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/AdminPerspective.vue'),

    children: [
        // Redirect to first child
        {
            path: '', 
            redirect: '/panel/admin/casino',
        },
        ...CasinoRoutes,
        ...EGMRoutes
    ]
};

export default route;