/**
 * Router for Technical Perspective > Zone
 */
const routes = [
    {
        path: 'zone/:zone_id',
        name: 'Technical/Zone',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/zone/ZonePage.vue'),
       
    },

];

export default routes;