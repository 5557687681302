/**
 * Router for Financial Perspective
 */
 import CasinoRoutes from '@/routes/financial/CasinoRoutes'; 

 
 const route = {
     path: 'financial',
     name: 'Financial',
     component: () => import( /* webpackChunkName: "financial" */ '@/views/financial/FinancialPerspective.vue'),
 
     children: [
         // Redirect to first child
         {
             path: '', 
             redirect: '/panel/financial/casino',
         },
         ...CasinoRoutes
     ]
 };
 
 export default route;