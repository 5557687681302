/**
 * Router for System Perspective > Manufacturer
 */
const routes = [
    {
        path:'manufacturer',
        name:'System/Manufacturer',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/ManufacturerList.vue'),
    },
    {
      path: 'manufacturer/:manufacturer_id',
      name: 'System/Manufacture',
      component: () => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/ManufacturerPage.vue'),
      children: [
        //Redirect to first child
        {
          path:'',
          redirect: '/panel/system/manufacturer/:manufacturer_id/cabinet'
        },
        //Cabinets tab
        {
          path:'cabinet',
          name:'System/Manufacturer/Cabinet',
          component: () => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/cabinets/CabinetsTab.vue'),
        },
        //Games tab
        {
          path:'game',
          name:'System/Manufacturer/Game',
          component: () => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/games/GamesTab.vue'),
        },
        //Game Mix tab
        {
          path:'mix',
          name:'System/Manufacturer/Mix',
          component: () => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/mix/MixTab.vue'),
        },
        //Machines tab
        {
          path:'machine',
          name:'System/Manufacturer/Machine',
          component: () => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/machines/MachinesTab.vue'),
        },
      ]
    },
    //Details page
    {
      path:'manufacturer/:manufacturer_id/cabinet/:cabinet_id',
      name:'System/Manufacturer/Detail',
      component:() => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/DetailsPage.vue'),
      children:[
        //Redirect to first child
        {
          path:'',
          redirect: '/panel/system/manufacturer/:manufacturer_id/cabinet/:cabinet_id/picture'
        },
        //Pictures tab
        {
          path:'picture',
          name:'System/Manufacturer/Detail/Picture',
          component:() => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/details/pictures/PicturesTab.vue'),
        },
        //Event Management tab
        {
          path:'eventManage',
          name:'System/Manufacturer/Detail/EventManage',
          component:() => import(/* webpackChunkName: "system" */ '@/views/system/manufacturer/details/event/EventManageTab.vue'),
        }
      ]
    },
];
export default routes;