/**
 * Router for Technical Perspective
 */
import CasinoRoutes from '@/routes/technical/CasinoRoutes'; 
import DeviceRoutes from '@/routes/technical/DeviceRoutes';
import EgmRoutes from '@/routes/technical/EgmRoutes';
import ManufacturerRoutes from '@/routes/technical/ManufacturerRoutes';
import ZoneRoutes from '@/routes/technical/ZoneRoutes';
import DebugRoutes from '@/routes/technical/DebugRoutes';

const route = {
    path: 'technical',
    name: 'Technical',
    component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/TechnicalPerspective.vue'),

    children: [
        // Redirect to first child
        {
            path: '', 
            redirect: '/panel/technical/casino',
        },
        ...CasinoRoutes,
        ...ManufacturerRoutes,
        ...DeviceRoutes,
        ...EgmRoutes,
        ...ZoneRoutes,
        ...DebugRoutes
    ]
};

export default route;