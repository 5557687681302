/**
 * Router for Admin Perspective > Casino
 */
const routes = [
    {
        path: 'casino',
        name: 'Admin/Casino',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/casino/CasinoList.vue'),
    },
    {
        path: 'casino/:casino_id',
        name: 'Admin/Casino',
        component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/casino/CasinoPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/admin/casino/:casino_id/egm',
            },
            // Zones Tab
            {
                path: 'zone',
                name: 'Admin/Casino/Zone',
                component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/casino/zones/ZonesTab.vue'),
            },
            // Manufactures Tab
            {
                path: 'manufacture',
                name: 'Admin/Casino/Manufacture',
                component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/casino/manufactures/ManufacturesTab.vue'),
            },
            // EGMs Tab
            {
                path: 'egm',
                name: 'Admin/Casinos/Egm',
                component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/casino/egms/EgmsTab.vue'),
            },
            // Devices Tab
            {
                path: 'device',
                name: 'Admin/Casino/Device',
                component: () => import( /* webpackChunkName: "admin" */ '@/views/admin/casino/devices/DevicesTab.vue'),
            },
        ]
    },

];

export default routes;