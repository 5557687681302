/**
 * Router for Technical Perspective > Manufacturer
 */
const routes = [
    {
        path:'manufacturer',
        name:'Technical/',
        component: () => import(/* webpackChunkName: "technical" */ '@/views/technical/manufacturer/ManufacturerList.vue'),
    },
    {
        path: 'manufacturer/:manufacturer_id',
        name: 'Technical/Manufacturer',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/manufacturer/ManufacturerPage.vue'),
       
    },

];

export default routes;