function getRefinedTodayDate(casinoOffset){                                                                              //casino offset in hours
    const newDate = new Date();
    const msDate = newDate.getTime();                                                                           //tranform date to miliseconds
    const todayDate = msDate - casinoOffset*60*60*1000;                                                         //subtract casino time offset to determine day in ms
    const todayDateIso = new Date(todayDate);                                                                   //create new Date object from offset date
    const timeZoneOffset = newDate.getTimezoneOffset();                                                         //get time-zone offset
    todayDateIso.setHours(casinoOffset-timeZoneOffset/60,0,0);                                                  //set casino offset hour to determined day
    const refinedTodayDate = todayDateIso.toISOString().substring(0, todayDateIso.toISOString().length-8);      //remove seconds and ms from date
    return  refinedTodayDate;
}

function calcDates(today,minus,period){
    let a = Vue.moment(today).subtract(minus,period).format();
    let b = a.substring(0, a.length-9);
    return b;
}

function startWeek(casinoOffset){
    let a = Vue.moment().startOf('isoWeek');
    let b = a.add(parseInt(casinoOffset),'h').format();
    let c = b.substring(0, b.length-9);
    
    return c;
}   

const state = {
    selectedRange: null,
    filterDate:null,
    date : {
        startDate : null,
        endDate: null,
        cStartDate: null,
        cEndDate: null,
    }
};

const getters = {
    selectedRange(state) {
        return state.selectedRange;
    },
    dates(state) {
        return state.date;
    },
    filterDate(state) {
        return state.filterDate;
    },
    
};
const actions = {

    setFilterDate({commit}, payload) {
        const dateObj = {};
        if(payload.allDay === true){
            dateObj.startDate = Vue.moment(calcDates(payload.date,payload.startDateInc,payload.incrementType)).format("DD MMM YY");
            dateObj.endDate = dateObj.startDate;
            dateObj.cStartDate = Vue.moment(calcDates(payload.date,payload.cStartDateInc,payload.incrementType)).format("DD MMM YY");
            dateObj.cEndDate = dateObj.cStartDate
        } else {
            dateObj.startDate = Vue.moment(calcDates(payload.date,payload.startDateInc,payload.incrementType)).format("DD MMM YY");
            dateObj.endDate = Vue.moment(calcDates(payload.date,payload.endDateInc,payload.incrementType)).format("DD MMM YY");
            dateObj.cStartDate = Vue.moment(calcDates(payload.date,payload.cStartDateInc,payload.incrementType)).format("DD MMM YY");
            dateObj.cEndDate = Vue.moment(calcDates(payload.date,payload.cEndDateInc,payload.incrementType)).format("DD MMM YY");
        }       
        
        const filter_date = 'startDate='+calcDates(payload.date,payload.startDateInc,payload.incrementType) +'&endDate='+calcDates(payload.date,payload.endDateInc,payload.incrementType)+'&cStartDate='+ calcDates(payload.date,payload.cStartDateInc,payload.incrementType) +'&cEndDate='+ calcDates(payload.date,payload.cEndDateInc,payload.incrementType);
        
        commit('SET_DATE', dateObj);
        commit('SET_FILTER_DATE', filter_date);
    },

    getDate({commit, dispatch}, payloadData) {
       
        const dateObj = {};
        const payload = {
            date: null,
            startDateInc: null,
            endDateInc: null,
            cStartDateInc:null,
            cEndDateInc:null,
            incrementType:null
        }

        switch (payloadData.item.label) {
            case 'Today':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 0;
                payload.endDateInc= -1;
                payload.cStartDateInc= 1;
                payload.cEndDateInc= 0;
                payload.incrementType= 'days';
                payload.allDay = true;
               
                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
               
                break;
            case 'Yesterday':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 1;
                payload.endDateInc= 0;
                payload.cStartDateInc= 2;
                payload.cEndDateInc= 1;
                payload.incrementType= 'days';
                payload.allDay = true;

                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
                break;
            case 'This Week':
               
                
                dateObj.startDate = Vue.moment(startWeek(payloadData.casinoData.settings.hourOffset)).format("DD MMM YY");
                dateObj.endDate = Vue.moment(calcDates(getRefinedTodayDate(payloadData.casinoData.settings.hourOffset),0,'days')).format("DD MMM YY");
                dateObj.cStartDate = Vue.moment(calcDates(startWeek(payloadData.casinoData.settings.hourOffset),7,'days')).format("DD MMM YY");
                dateObj.cEndDate = Vue.moment(calcDates(startWeek(payloadData.casinoData.settings.hourOffset),0,'days')).format("DD MMM YY");

                const filter_date_tWeek = 'startDate='+startWeek(payloadData.casinoData.settings.hourOffset)+'&endDate='+calcDates(getRefinedTodayDate(payloadData.casinoData.settings.hourOffset),0,'days')+'&cStartDate='+ calcDates(startWeek(payloadData.casinoData.settings.hourOffset),7,'days') +'&cEndDate='+ calcDates(startWeek(payloadData.casinoData.settings.hourOffset),0,'days'); 

                commit('SET_DATE', dateObj);
                commit('SET_FILTER_DATE', filter_date_tWeek);
                commit('SET_RANGE', payloadData.item.label);
              
                break;
            case 'Last Week':
                
                
                dateObj.startDate = Vue.moment(calcDates(startWeek(payloadData.casinoData.settings.hourOffset),7,'days')).format("DD MMM YY");
                dateObj.endDate = Vue.moment(calcDates(startWeek(payloadData.casinoData.settings.hourOffset),0,'days')).format("DD MMM YY");
                dateObj.cStartDate = Vue.moment(calcDates(startWeek(payloadData.casinoData.settings.hourOffset),14,'days')).format("DD MMM YY");
                dateObj.cEndDate = Vue.moment(calcDates(startWeek(payloadData.casinoData.settings.hourOffset),7,'days')).format("DD MMM YY");

                const filter_date_lWeek = 'startDate='+ calcDates(startWeek(payloadData.casinoData.settings.hourOffset),7,'days')+'&endDate='+calcDates(startWeek(payloadData.casinoData.settings.hourOffset),0,'days')+'&cStartDate='+ calcDates(startWeek(payloadData.casinoData.settings.hourOffset),14,'days') +'&cEndDate='+ calcDates(startWeek(payloadData.casinoData.settings.hourOffset),7,'days');
                
                commit('SET_DATE', dateObj);
                commit('SET_FILTER_DATE', filter_date_lWeek);
                commit('SET_RANGE', payloadData.item.label);
               
                break;
             case 'Last 7 days':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 7;
                payload.endDateInc= 0;
                payload.cStartDateInc= 14;
                payload.cEndDateInc= 7;
                payload.incrementType= 'days';
               
                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
              
                break;
            case 'Last 28 days':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 28;
                payload.endDateInc= 0;
                payload.cStartDateInc= 56;
                payload.cEndDateInc= 28;
                payload.incrementType= 'days';
                
                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
             
                break;
            case 'Last 30 days':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 30;
                payload.endDateInc= 0;
                payload.cStartDateInc= 60;
                payload.cEndDateInc= 30;
                payload.incrementType= 'days';

                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
              
                break;
            case 'Last 90 days':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 90;
                payload.endDateInc= 0;
                payload.cStartDateInc= 180;
                payload.cEndDateInc= 90;
                payload.incrementType= 'days';

                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
               
                break;
            case 'Last 12 months':
                payload.date= getRefinedTodayDate(payloadData.casinoData.settings.hourOffset);
                payload.startDateInc= 12;
                payload.endDateInc= 0;
                payload.cStartDateInc= 24;
                payload.cEndDateInc= 12;
                payload.incrementType= 'months';

                dispatch('setFilterDate', payload);
                commit('SET_RANGE', payloadData.item.label);
                break;
        }

        //this.$emit('currentRange', item);

        //this.filterDate = null;
    },

    getCustomDate({commit},payloadData){
        const rangeDate = {};
        const dateObj = {};
        
        if(payloadData.range.length == 1){
            let a = Vue.moment(payloadData.range[0]).add(payloadData.casinoData.settings.hourOffset,'h').format();;
            let b = a.substring(0, a.length-9);
            let c = Vue.moment(b).add(1,'days').format();

            rangeDate.startDate = b;
            rangeDate.endDate = c.substring(0, c.length-9);
            
            dateObj.startDate = Vue.moment(rangeDate.startDate).format("DD MMM YY");
            dateObj.endDate = Vue.moment(rangeDate.startDate).format("DD MMM YY");
            
        } else if (payloadData.range.length == 2){
            let moments = payloadData.range.map(d => Vue.moment(d))
            let maxF = Vue.moment.max(moments).add(payloadData.casinoData.settings.hourOffset,'h').format()
            let max = maxF.substring(0, maxF.length-9);

            let minF = Vue.moment.min(moments).add(payloadData.casinoData.settings.hourOffset,'h').format();
            let min = minF.substring(0, minF.length-9);
            
            rangeDate.startDate = min;
            rangeDate.endDate = max;

            dateObj.startDate = Vue.moment(rangeDate.startDate).format("DD MMM YY");
            dateObj.endDate = Vue.moment(rangeDate.endDate).format("DD MMM YY");

        }

        if(payloadData.cRange.length == 1){
            let a = Vue.moment(payloadData.cRange[0]).add(payloadData.casinoData.settings.hourOffset,'h').format();;
            let b = a.substring(0, a.length-9);
            let c = Vue.moment(b).add(1,'days').format();

            rangeDate.cStartDate = b;
            rangeDate.cEndDate = c.substring(0, c.length-9);
                            
            dateObj.cStartDate = Vue.moment(rangeDate.cStartDate).format("DD MMM YY");
            dateObj.cEndDate = Vue.moment(rangeDate.cStartDate).format("DD MMM YY");

        } else if (payloadData.cRange.length == 2){
            let moments = payloadData.cRange.map(d => Vue.moment(d))
            let maxF = Vue.moment.max( moments).add(payloadData.casinoData.settings.hourOffset,'h').format()
            let max = maxF.substring(0, maxF.length-9);

            let minF = Vue.moment.min( moments).add(payloadData.casinoData.settings.hourOffset,'h').format();
            let min = minF.substring(0, minF.length-9);

            rangeDate.cStartDate = min;
            rangeDate.cEndDate = max;

            dateObj.cStartDate = Vue.moment(rangeDate.cStartDate).format("DD MMM YY");
            dateObj.cEndDate = Vue.moment(rangeDate.cEndDate).format("DD MMM YY");
        }
        
        commit('SET_RANGE',payloadData.item.label);
        commit('SET_DATE', dateObj);        

        const filter_date = 'startDate='+rangeDate.startDate+'&endDate='+rangeDate.endDate+'&cStartDate='+ rangeDate.cStartDate +'&cEndDate='+ rangeDate.cEndDate;
        commit('SET_FILTER_DATE', filter_date);
            
    },
};
const mutations = {
    SET_DATE(state, dateObj){
        state.date.startDate = dateObj.startDate;
        state.date.endDate = dateObj.endDate;
        state.date.cStartDate = dateObj.cStartDate;
        state.date.cEndDate = dateObj.cEndDate;
    },
    SET_FILTER_DATE(state, filterDate){
        state.filterDate = filterDate;
    },

    SET_RANGE(state, range){
        state.selectedRange = range;
    }
    
};

export default {
	state,
	getters,
	actions, 
	mutations
}