/**
 * Router for Technical Perspective > Casino
 */
const routes = [
    {
        path: 'casino',
        name: 'Technical/Casino',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/casino/CasinoList.vue'),
    },
    {
        path: 'casino/:casino_id',
        name: 'Technical/Casino',
        component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/casino/CasinoPage.vue'),
        children: [
            // Redirect to first child
            {
                path: '',
                redirect: '/panel/technical/casino/:casino_id/physical',
            },
            // Physical Tab
            {
                path: 'physical',
                name: 'Technical/Casino/Physical',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/casino/physical/PhysicalTab.vue'),
            },
            // Virtual Tab
            {
                path: 'virtual',
                name: 'Technical/Casino/Virtual',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/casino/virtual/VirtualTab.vue'),
            },
            // Play Tab
            {
                path: 'play',
                name: 'Technical/Casino/Play',
                component: () => import( /* webpackChunkName: "technical" */ '@/views/technical/casino/play/PlayTab.vue'),
            }
        ]
    },

];

export default routes;